// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_reactourPopover__QY5PT svg {
  color: #fff !important;
}

.styled_reactourMask__Bhq-O {
  color: #000;
}

.styled_reactourButton__Xjmkj {
  border: 1px solid #ba00fb !important;
  background: rgba(58, 31, 80, 0.6) !important;
  padding: 0.5rem 2rem !important;
}`, "",{"version":3,"sources":["webpack://./src/hooks/reactourProvider/styled.module.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,oCAAA;EACA,4CAAA;EACA,+BAAA;AADF","sourcesContent":[".reactourPopover {\n  svg {\n    color: #fff !important;\n  }\n}\n\n.reactourMask {\n  color: #000;\n}\n\n.reactourButton {\n  border: 1px solid #ba00fb!important;\n  background: rgba(58, 31, 80, 0.6)!important;\n  padding: 0.5rem 2rem!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reactourPopover": `styled_reactourPopover__QY5PT`,
	"reactourMask": `styled_reactourMask__Bhq-O`,
	"reactourButton": `styled_reactourButton__Xjmkj`
};
export default ___CSS_LOADER_EXPORT___;
