// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
}

body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #150a21;
  overflow: hidden;
  box-sizing: border-box;

}

#root {
  /*border: 2px solid red;*/
  position: relative;
}


/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #b800fb20;
  border-radius: 10px;
  margin: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ba00fb60;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,SAAS;EACT;wEACsE;EACtE,mCAAmC;EACnC,kCAAkC;EAClC,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;;AAExB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;;AAGA,sBAAsB;AACtB;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":["html {\n  scroll-behavior: smooth;\n  overflow: hidden;\n  box-sizing: border-box;\n  height: 100%;\n}\n\nbody,\n#root {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\",\n    \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100%;\n  background: #150a21;\n  overflow: hidden;\n  box-sizing: border-box;\n\n}\n\n#root {\n  /*border: 2px solid red;*/\n  position: relative;\n}\n\n\n/* Scrollbar Styling */\n::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n::-webkit-scrollbar-track {\n  background: #b800fb20;\n  border-radius: 10px;\n  margin: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #ba00fb60;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
