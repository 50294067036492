import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

import { useDisconnect, useActiveWallet, useActiveWalletConnectionStatus, useActiveAccount } from "thirdweb/react";

import { chain } from "config/thirdwebConfig";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { fetchChallengeRequest, selectMessageAuth, selectMessageAuthLoading } from "reduxConfig/thunks/messageAuth";
import { fetchChallengeVerify } from "reduxConfig/thunks/tokenAuth";
import { clearAuthToken } from "reduxConfig/slices/tokenAuth";
import { clearMessageAuth } from "reduxConfig/slices/messageAuth";
import { getSocial } from "reduxConfig/thunks/social";
import { clearSocial } from "reduxConfig/slices/social";
import { getUserInfo } from "reduxConfig/thunks/userInfo";

import CustomTooltip from "components/atoms/materialTooltip";
import useAlert from "hooks/alertProvider/useAlert";

import LogoutIcon from "@mui/icons-material/Logout";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import styled from "./styled.module.scss";

const LoginBar = () => {
  const tokenLS = localStorage.getItem("session.token");
  const accountLS = JSON.parse(localStorage.getItem("session.account") || "{}");
  const { setAlert } = useAlert();

  const [loadSigning, setLoadSigning] = useState(false);
  const [signMessage, setSignedMessage] = useState(false);
  const [searchParams] = useSearchParams();

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const wallet = useActiveWallet();
  const account = useActiveAccount() || accountLS;
  const status = useActiveWalletConnectionStatus();
  const { disconnect } = useDisconnect();

  const dataMessageAuth = useSelector(selectMessageAuth);
  const loadingMessageAuth = useSelector(selectMessageAuthLoading);
  const social = useSelector(getSocial);

  const userInfo = useSelector(getUserInfo);

  const logout = () => {
    if (wallet) disconnect(wallet);

    window?.cookie3?.trackEvent({
      category: "Button Click",
      action: "Logout",
      value: 1,
    });

    setSignedMessage(false);
    setLoadSigning(false);
    dispatch(clearAuthToken());
    dispatch(clearMessageAuth());
    localStorage.removeItem("session.token");
    localStorage.removeItem("session.account");

    const platform = window?.Telegram?.WebApp?.platform || "";
    console.log("platform", platform);

    if (platform !== "unknown") {
      window?.Telegram?.WebApp?.close();
    } else {
      navigate(`/${i18n.language}/`);
    }
  };

  const goToHome = () => {
    const searchValue = searchParams.get("origin") || "";
    searchParams.delete("token");
    if (searchValue) {
      console.log("searchValue", searchValue);
      navigate(`/${i18n.language}/${decodeURIComponent(searchValue)}`);
    } else {
      navigate(`/${i18n.language}/home/?${decodeURIComponent(searchParams.toString())}`);
    }
  };

  const signedMessage = async (message: any) => {
    try {
      const response = await account?.signMessage({ message });
      setSignedMessage(response);
    } catch (error) {
      logout();
      setAlert(t("login-error-rejected"), "error");
    }
  };

  useEffect(() => {
    const address = account?.address;
    const chainid = chain?.id || 1;

    if (status === "connected" && address && !signMessage && !tokenLS) {
      const from = window.location.hostname;
      if (!loadingMessageAuth) {
        setLoadSigning(true);
        dispatch(fetchChallengeRequest({ address, from, chainid }) as any).then(async (response: any) => {
          if (response?.message) {
            signedMessage(response.message);
          } else {
            setAlert(t("login-error-request"), "error");
            console.error("Error to challenge request: ", response);
            logout();
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, status]);

  useEffect(() => {
    if (signMessage && dataMessageAuth?.message && social !== null) {
      const token = searchParams.get("token") || "";
      searchParams.delete("token");

      const signParams = { signature: signMessage, message: dataMessageAuth.message, social, token };
      dispatch(fetchChallengeVerify(signParams) as any).then(async (response: any) => {
        setLoadSigning(false);
        dispatch(clearSocial());
        dispatch(clearMessageAuth());

        if (response?.sessionToken) {
          localStorage.setItem("session.token", response?.sessionToken);
          localStorage.setItem("session.account", JSON.stringify({ ...account, ...signParams }));
          setSignedMessage(false);
          goToHome();
        } else {
          setAlert(t("login-error-verify"), "error");
          console.error("Error to challenge verify: ", response);
          logout();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessage, dataMessageAuth?.message, social]);

  useEffect(() => {
    const isLoginView = !location.pathname.split("/")[2];
    if (isLoginView && account && tokenLS) {
      goToHome();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLS, account, status]);

  /*const valideActiveAddress = async () => {
    console.log("OLD account", account);
    await dispatch(fetchLeaderboard() as any).then(async (response: any) => {
      const walletActive = response?.result?.wallets.find((w: any) => w?.active && w.type === "me3-created") || [];
      if (
        account?.address &&
        walletActive?.address &&
        account?.address.toLowerCase() !== walletActive?.address.toLowerCase()
      ) {
        await customAxios()
          .post("/wallet/key", { address: walletActive?.address })
          .then(async (response) => {
            const personalAccount = privateKeyToAccount({
              client,
              privateKey: response?.data?.data,
            });

            console.log("personalAccount", personalAccount);

            // aca setear account activa
          });
      }
    });
  };*/

  useEffect(() => {
    window.addEventListener("logout", () => logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {account?.address && (
        <Box className={styled.loggedBox}>
          {loadSigning ? (
            <>
              <CircularProgress className={styled.spinner} size={20} />
              <span>{t("login-signing")}</span>
            </>
          ) : (
            <>
              <span>{userInfo?.userNickname || ""}</span>
              <AccountCircleIcon />

              {/*<AutoConnect timeout={10000} {...modalConfig} />*/}
            </>
          )}

          <Box className={styled.divider} />

          <CustomTooltip title={t("disconect")}>
            <LogoutIcon onClick={logout} />
          </CustomTooltip>
        </Box>
      )}
    </>
  );
};
export default LoginBar;
