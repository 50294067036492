import { configureStore } from "@reduxjs/toolkit";

import claim from "reduxConfig/slices/claim";
import maps from "reduxConfig/slices/maps";
import game from "reduxConfig/slices/game";
import social from "reduxConfig/slices/social";

import tokenAuth from "reduxConfig/slices/tokenAuth";
import messageAuth from "reduxConfig/slices/messageAuth";
import leaderboardList from "reduxConfig/slices/leaderboardList";
import campaigns from "reduxConfig/slices/campaigns";
import userInfo from "reduxConfig/slices/userInfo";

const store = configureStore({
  reducer: {
    claim: claim,
    maps: maps,
    campaigns: campaigns,
    game: game,
    social: social,
    tokenAuth: tokenAuth,
    userInfo: userInfo,
    leaderboardList: leaderboardList,
    messageAuth: messageAuth,
  },
});

export default store;
