import { Box, CircularProgress, Modal, Typography } from "@mui/material";
//import CloseIcon from "@mui/icons-material/Close";
import Button from "components/atoms/buttons/default";
import ButtonBase from "components/atoms/buttons/base";
import capsuleIcon from "assets/icons/capsule.svg";
import Zoom from "@mui/material/Zoom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "./styled.module.scss";

const CongratsModal = ({ open = false, data, onClose, handlePlayAgain }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const rewardVideo = data?.rewardVideo ? data?.rewardVideo[0]?.url : null;
  const rewardImage = data?.rewardImage ? data?.rewardImage[0]?.url : null;

  const viewRewards = () => {
    const mapCode = location.state?.map || "";
    navigate(`/${i18n.language}/home/${mapCode}`, { state: { openRewards: true } });
  };

  return (
    <Modal open={open} onClose={onClose} className={styled.modalContainer}>
      <>
        <Box className={styled.backButton}>
          <ButtonBase onClick={(evt: any) => onClose(evt, "close")}>
            <ArrowBackIcon /> {t("back")}
          </ButtonBase>
        </Box>
        <Box className={styled.modal}>
          {/*<CloseIcon className={styled.close} onClick={(evt: any) => onClose(evt, "close")} />*/}

          <Zoom in={open}>
            <Box className={styled.content}>
              <Box className={styled.congratulations} />

              <Typography pt={2} className={styled.title}>
                Congratulations!
              </Typography>
              <Typography pb={0} className={styled.subtitle}>
                You won:
              </Typography>

              <Box className={styled.reward}>
                {rewardVideo ? (
                  <>
                    <CircularProgress className={styled.loader} size={40} />
                    <video loop={true} autoPlay={true} controls={false} preload="auto" muted playsInline>
                      <source src={rewardVideo} type="video/mp4" />
                    </video>
                  </>
                ) : rewardImage ? (
                  <img src={rewardImage} alt="reward" className={styled.rewardImage} />
                ) : (
                  <img src={capsuleIcon} alt="capsule" />
                )}
              </Box>

              <Typography pt={2} pb={2} className={styled.prize}>
                {data?.prize || ""}
              </Typography>

              {data?.rewardText && (
                <Typography
                  dangerouslySetInnerHTML={{ __html: data?.rewardText || "" }}
                  pb={3}
                  className={styled.code}
                />
              )}

              <Box className={styled.footer} gap={"1rem"}>
                <Box className={styled.item}>
                  <Button onClick={viewRewards}>VIEW REWARDS COLLECTION</Button>
                </Box>
                <Box className={styled.item}>
                  <Button onClick={handlePlayAgain}>PLAY AGAIN</Button>
                </Box>
              </Box>
            </Box>
          </Zoom>
        </Box>
      </>
    </Modal>
  );
};
export default CongratsModal;
