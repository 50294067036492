import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo, getUserInfo } from "reduxConfig/thunks/userInfo";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import rotateIcon from "assets/icons/rotate.svg";

import Button from "components/atoms/buttons/base";
import Layout from "components/templates/layout";
import GameBar from "components/organisms/game/bar";
import GameContainer from "components/organisms/game/container";

import useAlert from "hooks/alertProvider/useAlert";
import { getGame } from "reduxConfig/thunks/game";

import styled from "./styled.module.scss";
import customAxios from "utils/customAxios";

const Game = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();
  const { code } = useParams();
  const { setAlert } = useAlert();

  const userInfo = useSelector(getUserInfo);
  const game = useSelector(getGame);

  const [gameData, setGameData] = useState<any>(game || {});
  const [onPlay, setOnPlay] = useState<boolean>(false);
  const [balance, setBalance] = useState(0);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEnd = (response: any) => {
    if (response?.userAvailableKeys) {
      setBalance(response.userAvailableKeys);
    } else {
      setBalance(balance - gameData?.price);
    }
    setOnPlay(false);
  };

  const handlePlay = () => {
    if (balance === 0 || balance - gameData?.price < 0) {
      setAlert(t("game-no-balance"), "error");
    } else {
      setOnPlay(true);
    }
  };

  useEffect(() => {
    if (game) {
      setGameData(game);
    } else {
      const listGames = JSON.parse(localStorage.getItem("games") || "");
      const game = listGames.find((game: any) => game.code === code) || null;
      setGameData(game);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  useEffect(() => {
    if (userInfo) {
      setBalance(userInfo?.userKeys || 0);
    } else {
      dispatch(fetchUserInfo() as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (!gameData) setAlert("Game not found", "error");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData]);

  useEffect(() => {
    customAxios()
      .get("/user/validatesession")
      .catch((error: any) => {
        console.error("session error ", error);
        navigate(`/${i18n.language}/`);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onPlay) containerRef?.current?.scrollTo(0, 0);
  }, [onPlay]);

  useEffect(() => {
    //console.log(window?.Telegram?.WebApp);
    window.Telegram?.WebApp?.ready();
    window.Telegram?.WebApp?.expand();
    window.Telegram?.WebApp?.disableVerticalSwipes();
  }, []);

  return (
    <Layout>
      <Container ref={containerRef} maxWidth={false} disableGutters={true} className={styled.mobileView}>
        {/*<Box className={styled.mobileRotate}>
          <Box>
            <img src={rotateIcon} alt="rotate" />
            <Typography>{t("game-rotate")}</Typography>
          </Box>
        </Box>*/}

        <Box className={styled.backButton}>
          <Button onClick={handleBack}>
            <ArrowBackIcon /> {t("back")}
          </Button>
        </Box>

        <Grid container className={styled.main}>
          <Grid item container xs={12} md className={styled.container}>
            <GameContainer
              onPlay={onPlay}
              balance={balance}
              handleEnd={handleEnd}
              handlePlay={handlePlay}
              gameData={gameData}
            />
          </Grid>

          <Grid item container xs={12} md="auto" className={styled.rightCol}>
            <GameBar onPlay={onPlay} balance={balance} handlePlay={handlePlay} gameData={gameData} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
export default Game;
