import { useEffect } from "react";
import { Box, CircularProgress, Fade, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import user from "assets/icons/user.svg";
import arrowUp from "assets/icons/arrowUp.svg";
import arrowDown from "assets/icons/arrowDown.svg";
import StarIcon from "@mui/icons-material/Star";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import styled from "./styled.module.scss";
import RenderAdvertisement from "components/molecules/advertisement";
import { getCampaigns } from "reduxConfig/thunks/campaigns";
import { urlRegex } from "utils/regex";
import { fetchLeaderboardList, getLeaderboardList } from "reduxConfig/thunks/leaderboardList";

const Leaderboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaignsData = useSelector(getCampaigns);
  const leaderboardData = useSelector(getLeaderboardList);
  const loading = useSelector((state: any) => state.leaderboardList.loading);

  const hasValidUrl = urlRegex.test(campaignsData?.viewUrl);

  useEffect(() => {
    dispatch(fetchLeaderboardList() as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container component="section" flexDirection="column" className={styled.main}>
      <Fade in={true} timeout={500}>
        <Box p={1} className={styled.container}>
          <Typography className={styled.subtitle}>TOP 1000 LEADERBOARD</Typography>
          <Box className={styled.listData}>
            <Box className={styled.border}></Box>
            <TableContainer className={styled.table}>
              <Table>
                <tbody>
                  {loading ? (
                    <TableRow className={styled.content}>
                      <TableCell>
                        <CircularProgress className={styled.spinner} size={26} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {leaderboardData?.ranking ? (
                        leaderboardData.ranking?.map((row: any) => (
                          <TableRow key={row.userId}>
                            <TableCell className={styled.user} width={"60%"}>
                              <img src={user} alt="user" />
                              <span>{row.position}</span>
                              <Typography>{row.nickname}</Typography>
                            </TableCell>
                            <TableCell align="right" width={"5%"} className={styled.arrow}>
                              {row.position === 1 ? (
                                <StarIcon />
                              ) : (
                                <img src={row.isUp ? arrowUp : arrowDown} alt="arrow" />
                              )}
                            </TableCell>
                            <TableCell align="right" width={"35%"} sx={{ paddingLeft: "0!important" }}>
                              {row?.points?.toFixed(0) || 0} pts
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            {t("no-data")}
                          </TableCell>
                        </TableRow>
                      )}
                      {hasValidUrl && (
                        <TableRow>
                          <TableCell>
                            <RenderAdvertisement minImg={true} />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Fade>
    </Grid>
  );
};
export default Leaderboard;
