import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfoStart(state) {
      state.loading = true;
      state.error = null;
    },
    setUserInfoSuccess(state, { payload }) {
      state.data = payload;
      state.loading = false;
    },
    setUserInfoFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    clearUserInfo(state) {
      state.data = initialState.data;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
});

export const { setUserInfoStart, setUserInfoSuccess, setUserInfoFailure, clearUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
