// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_menuContainer__lCCq5 {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  max-height: inherit;
  overflow: hidden;
}
.styled_menuContainer__lCCq5 .styled_item__bBz7W {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed rgba(186, 0, 251, 0.6666666667);
  padding: 24px 8px;
  color: #e4bcf2;
  cursor: pointer;
}
.styled_menuContainer__lCCq5 .styled_item__bBz7W p {
  width: 100%;
  padding: 0 20px;
  text-align: start;
  font-family: "ChakraPetch";
  font-size: 1rem;
  font-weight: 400;
}
.styled_menuContainer__lCCq5 .styled_item__bBz7W svg {
  fill: #db74ff;
}
.styled_menuContainer__lCCq5 .styled_sectionItem__YY-ix {
  top: 0;
  position: relative;
  left: 100%;
  width: 100%;
  transition: 0.2s all;
  box-sizing: border-box;
  max-height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/newMenu/sections/main/menu/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,yDAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;AACN;AAEI;EACE,aAAA;AAAN;AAIE;EACE,MAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".menuContainer {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  transition: all 0.3s;\n  max-height: inherit;\n  overflow: hidden;\n\n  .item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px dashed #ba00fbaa;\n    padding: 24px 8px;\n    color: #e4bcf2;\n    cursor: pointer;\n\n    p {\n      width: 100%;\n      padding: 0 20px;\n      text-align: start;\n      font-family: \"ChakraPetch\";\n      font-size: 1rem;\n      font-weight: 400;\n    }\n\n    svg {\n      fill: #db74ff;\n    }\n  }\n\n  .sectionItem {\n    top: 0;\n    position: relative;\n    left: 100%;\n    width: 100%;\n    transition: 0.2s all;\n    box-sizing: border-box;\n    max-height: inherit;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `styled_menuContainer__lCCq5`,
	"item": `styled_item__bBz7W`,
	"sectionItem": `styled_sectionItem__YY-ix`
};
export default ___CSS_LOADER_EXPORT___;
