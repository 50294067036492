import AppRouter from "routes";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.Telegram?.WebApp?.ready();
    window.Telegram?.WebApp?.expand();
    window.Telegram?.WebApp?.disableVerticalSwipes();
  }, []);
  
  return <AppRouter />;
}

export default App;
