// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_main__ZGIgf .styled_item__PUbvq {
  text-align: start;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.styled_main__ZGIgf .styled_item__PUbvq.styled_active__3bdTB {
  background-color: rgba(219, 116, 255, 0.1333333333);
}
.styled_main__ZGIgf .styled_item__PUbvq.styled_active__3bdTB .styled_title__9x5zk,
.styled_main__ZGIgf .styled_item__PUbvq.styled_active__3bdTB .styled_desc__q5YYn {
  color: #db74ff;
}
.styled_main__ZGIgf .styled_item__PUbvq > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 1;
}
.styled_main__ZGIgf .styled_item__PUbvq > div .styled_title__9x5zk,
.styled_main__ZGIgf .styled_item__PUbvq > div .styled_desc__q5YYn {
  transition: 0.2s all;
  color: rgba(255, 255, 255, 0.8);
}
.styled_main__ZGIgf .styled_item__PUbvq > div .styled_title__9x5zk {
  font-family: "OrbitronBold";
  font-size: 1rem;
}
.styled_main__ZGIgf .styled_item__PUbvq > div .styled_desc__q5YYn {
  font-family: "ChakraPetchLight";
  font-size: 0.9rem;
  overflow: hidden;
  max-height: 65px;
}
.styled_main__ZGIgf .styled_item__PUbvq svg {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
}
.styled_main__ZGIgf .styled_item__PUbvq:hover > div .styled_title__9x5zk,
.styled_main__ZGIgf .styled_item__PUbvq:hover > div .styled_desc__q5YYn {
  color: #db74ff;
}
.styled_main__ZGIgf .styled_item__PUbvq:hover svg {
  color: #db74ff;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/newMenu/sections/main/maps/styled.module.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,eAAA;EACA,qBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAEI;EAKE,mDAAA;AAJN;AAAM;;EAEE,cAAA;AAER;AAGI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,SAAA;AADN;AAGM;;EAEE,oBAAA;EACA,+BAAA;AADR;AAIM;EACE,2BAAA;EACA,eAAA;AAFR;AAKM;EACE,+BAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AAHR;AAOI;EACE,+BAAA;EACA,iBAAA;AALN;AAUM;;EAEE,cAAA;AARR;AAWI;EACE,cAAA;AATN","sourcesContent":[".main {\n  .item {\n    text-align: start;\n    padding: 0.5rem;\n    border-radius: 0.5rem;\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n\n    &.active {\n      .title,\n      .desc {\n        color: #db74ff;\n      }\n      background-color: #db74ff22;\n    }\n\n    & > div {\n      display: flex;\n      flex-direction: column;\n      align-items: flex-start;\n      gap: 0.25rem;\n      flex: 1;\n\n      .title,\n      .desc {\n        transition: 0.2s all;\n        color: #ffffffcc;\n      }\n\n      .title {\n        font-family: \"OrbitronBold\";\n        font-size: 1rem;\n      }\n\n      .desc {\n        font-family: \"ChakraPetchLight\";\n        font-size: 0.9rem;\n        overflow: hidden;\n        max-height: 65px;\n      }\n    }\n\n    svg {\n      color: #ffffffcc;\n      font-size: 1.5rem;\n    }\n  }\n  .item:hover {\n    & > div {\n      .title,\n      .desc {\n        color: #db74ff;\n      }\n    }\n    svg {\n      color: #db74ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `styled_main__ZGIgf`,
	"item": `styled_item__PUbvq`,
	"active": `styled_active__3bdTB`,
	"title": `styled_title__9x5zk`,
	"desc": `styled_desc__q5YYn`
};
export default ___CSS_LOADER_EXPORT___;
