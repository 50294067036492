// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_menu__1uknb .styled_item__xrP5D {
  font-family: "Orbitron", "ChakraPetch";
  color: #efc6ff;
  font-size: 0.9em;
  text-transform: uppercase;
  transition: all 0.2s;
}
.styled_menu__1uknb .styled_item__xrP5D:hover {
  background: rgba(186, 0, 251, 0.2509803922) !important;
  color: #fff;
}
.styled_menu__1uknb .styled_item__xrP5D img {
  width: 25px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/languaje/styled.module.scss"],"names":[],"mappings":"AAEE;EACE,sCAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;EACA,oBAAA;AADJ;AAGI;EACE,sDAAA;EACA,WAAA;AADN;AAII;EACE,WAAA;EACA,kBAAA;AAFN","sourcesContent":[".menu {\n\n  .item{\n    font-family: \"Orbitron\", \"ChakraPetch\";\n    color: #efc6ff;\n    font-size: .9em;\n    text-transform: uppercase;\n    transition: all 0.2s;\n\n    &:hover {\n      background: #ba00fb40 !important;\n      color: #fff;\n    }\n  \n    img {\n      width: 25px;\n      margin-right: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `styled_menu__1uknb`,
	"item": `styled_item__xrP5D`
};
export default ___CSS_LOADER_EXPORT___;
