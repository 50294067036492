// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_appLayout__\\+N7b6 {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/templates/layout/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EAEA,sBAAA;AAAF","sourcesContent":[".appLayout {\n  display: flex;\n  width: 100%;\n  //height: 100svh;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayout": `styled_appLayout__+N7b6`
};
export default ___CSS_LOADER_EXPORT___;
