// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_carousel__qCzJE {
  padding: 0;
  width: calc(100% - 100px);
  max-width: 1000px;
  height: 500px;
  box-sizing: border-box;
  z-index: 999;
}
.styled_carousel__qCzJE ul li {
  display: flex;
  justify-content: center;
}

.styled_arrowContainer__W7x7b {
  padding: 0;
  width: calc(100% - 50px);
  max-width: 1100px;
  align-items: center;
  height: 500px;
  box-sizing: border-box;
  position: absolute;
}
.styled_arrowContainer__W7x7b .styled_arrowLeft__TwLxM {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 903;
  color: #db74ff;
  font-size: 2rem !important;
}
.styled_arrowContainer__W7x7b .styled_arrowRight__Pu4tq {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 903;
  color: #db74ff;
  font-size: 2rem !important;
}

@media screen and (max-width: 500px) {
  .styled_arrowContainer__W7x7b {
    width: calc(100% - 20px);
  }
}
@media screen and (max-height: 500px) {
  .styled_carousel__qCzJE,
  .styled_arrowContainer__W7x7b {
    height: 400px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/buildingDetails/carousel/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAEE;EACE,aAAA;EACA,uBAAA;AAAJ;;AAGA;EACE,UAAA;EACA,wBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAEE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,0BAAA;AAAJ;AAGE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,0BAAA;AADJ;;AAKA;EACE;IACE,wBAAA;EAFF;AACF;AAKA;EACE;;IAEE,aAAA;EAHF;AACF","sourcesContent":[".carousel {\n  padding: 0;\n  width: calc(100% - 100px);\n  max-width: 1000px;\n  height: 500px;\n  box-sizing: border-box;\n  z-index: 999;\n  //position: relative;\n\n  ul li {\n    display: flex;\n    justify-content: center;\n  }\n}\n.arrowContainer {\n  padding: 0;\n  width: calc(100% - 50px);\n  max-width: 1100px;\n  align-items: center;\n  height: 500px;\n  box-sizing: border-box;\n  position: absolute;\n\n  .arrowLeft {\n    position: absolute;\n    top: 50%;\n    left: 0px;\n    transform: translateY(-50%);\n    cursor: pointer;\n    z-index: 903;\n    color: #db74ff;\n    font-size: 2rem !important;\n  }\n\n  .arrowRight {\n    position: absolute;\n    top: 50%;\n    right: 0px;\n    transform: translateY(-50%);\n    cursor: pointer;\n    z-index: 903;\n    color: #db74ff;\n    font-size: 2rem !important;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .arrowContainer {\n    width: calc(100% - 20px);\n  }\n}\n\n@media screen and (max-height: 500px) {\n  .carousel,\n  .arrowContainer {\n    height: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": `styled_carousel__qCzJE`,
	"arrowContainer": `styled_arrowContainer__W7x7b`,
	"arrowLeft": `styled_arrowLeft__TwLxM`,
	"arrowRight": `styled_arrowRight__Pu4tq`
};
export default ___CSS_LOADER_EXPORT___;
