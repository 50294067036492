// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Teko-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/TitilliumWeb-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("assets/fonts/Aldrich-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("assets/fonts/KronaOne-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("assets/fonts/Orbitron-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("assets/fonts/Orbitron-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("assets/fonts/Orbitron-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("assets/fonts/ChakraPetch-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("assets/fonts/ChakraPetch-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("assets/fonts/ChakraPetch-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Teko";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}

@font-face {
  font-family: "TitilliumWeb";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}

@font-face {
  font-family: "Poppins";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}

@font-face {
  font-family: "PoppinsBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
}

@font-face {
  font-family: "Aldrich";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff");
}

@font-face {
  font-family: "KronaOne";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
}

@font-face {
  font-family: "Orbitron";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff");
}

@font-face {
  font-family: "OrbitronBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
}

@font-face {
  font-family: "OrbitronBlack";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff");
}

@font-face {
  font-family: "ChakraPetch";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("woff");
}

@font-face {
  font-family: "ChakraPetchLight";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("woff");
}

@font-face {
  font-family: "ChakraPetchBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff");
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2DAAuD;AACzD;;AAEA;EACE,2BAA2B;EAC3B,2DAAgE;AAClE;;AAEA;EACE,sBAAsB;EACtB,2DAA2D;AAC7D;;AAEA;EACE,0BAA0B;EAC1B,2DAAwD;AAC1D;;AAEA;EACE,sBAAsB;EACtB,2DAA2D;AAC7D;;AAEA;EACE,uBAAuB;EACvB,2DAA4D;AAC9D;;AAEA;EACE,uBAAuB;EACvB,2DAA4D;AAC9D;;AAEA;EACE,2BAA2B;EAC3B,2DAAyD;AAC3D;;AAEA;EACE,4BAA4B;EAC5B,2DAA0D;AAC5D;;AAEA;EACE,0BAA0B;EAC1B,2DAA+D;AACjE;;AAEA;EACE,+BAA+B;EAC/B,4DAA6D;AAC/D;;AAEA;EACE,8BAA8B;EAC9B,4DAA4D;AAC9D","sourcesContent":["@font-face {\n  font-family: \"Teko\";\n  src: url(\"assets/fonts/Teko-Medium.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"TitilliumWeb\";\n  src: url(\"assets/fonts/TitilliumWeb-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"Poppins\";\n  src: url(\"assets/fonts/Poppins-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"PoppinsBold\";\n  src: url(\"assets/fonts/Poppins-Bold.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"Aldrich\";\n  src: url(\"assets/fonts/Aldrich-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"KronaOne\";\n  src: url(\"assets/fonts/KronaOne-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"Orbitron\";\n  src: url(\"assets/fonts/Orbitron-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"OrbitronBold\";\n  src: url(\"assets/fonts/Orbitron-Bold.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"OrbitronBlack\";\n  src: url(\"assets/fonts/Orbitron-Black.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"ChakraPetch\";\n  src: url(\"assets/fonts/ChakraPetch-Regular.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"ChakraPetchLight\";\n  src: url(\"assets/fonts/ChakraPetch-Light.ttf\") format(\"woff\");\n}\n\n@font-face {\n  font-family: \"ChakraPetchBold\";\n  src: url(\"assets/fonts/ChakraPetch-Bold.ttf\") format(\"woff\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
