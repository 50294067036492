// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_actionsBar__U3aJ2 {
  display: flex;
  position: absolute;
  align-items: center;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 900;
  gap: 0.5rem;
  padding-bottom: 0;
  box-sizing: border-box;
}
.styled_actionsBar__U3aJ2 .styled_lngIcon__SXN0q,
.styled_actionsBar__U3aJ2 .styled_helpIcon__\\+tOsf {
  width: 34px;
  height: 34px;
  fill: #ba00fb;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  background-color: rgba(58, 31, 80, 0.6);
  backdrop-filter: blur(4px);
}
.styled_actionsBar__U3aJ2 .styled_helpIcon__\\+tOsf {
  transform: translateY(-2px);
}

.styled_spinner__ccHye {
  color: #d490ec !important;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/actionsbar/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,sBAAA;AACF;AACE;;EAEE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,uCAAA;EAEA,0BAAA;AACJ;AAEE;EACE,2BAAA;AAAJ;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".actionsBar {\n  display: flex;\n  position: absolute;\n  align-items: center;\n  top: 1.5rem;\n  right: 1.5rem;\n  z-index: 900;\n  gap: 0.5rem;\n  padding-bottom: 0;\n  box-sizing: border-box;\n\n  .lngIcon,\n  .helpIcon {\n    width: 34px;\n    height: 34px;\n    fill: #ba00fb;\n    cursor: pointer;\n    border-radius: 50%;\n    padding: 2px;\n    background-color: rgba(58, 31, 80, 0.6);\n    -webkit-backdrop-filter: blur(4px);\n    backdrop-filter: blur(4px);\n  }\n\n  .helpIcon {\n    transform: translateY(-2px);\n  }\n}\n\n.spinner {\n  color: #d490ec !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsBar": `styled_actionsBar__U3aJ2`,
	"lngIcon": `styled_lngIcon__SXN0q`,
	"helpIcon": `styled_helpIcon__+tOsf`,
	"spinner": `styled_spinner__ccHye`
};
export default ___CSS_LOADER_EXPORT___;
