// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_payEmbed__ZKsvv {
  position: absolute;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}
.styled_payEmbed__ZKsvv .styled_backButton__9BzRy {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 99999 !important;
}
.styled_payEmbed__ZKsvv .styled_backButton__9BzRy button {
  border: none !important;
  background-color: rgba(99, 67, 115, 0.4) !important;
  color: #db74ff;
  font-size: 0.9rem;
  padding: 5px 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/NFTChekout/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,0BAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;AACJ;AACI;EACE,uBAAA;EACA,mDAAA;EACA,cAAA;EACA,iBAAA;EACA,4BAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;AACN","sourcesContent":[".payEmbed {\n  position: absolute;\n  inset: 0;\n  z-index: 9999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(3px);\n\n  .backButton {\n    position: absolute;\n    top: 2.5rem;\n    left: 2.5rem;\n    z-index: 99999!important;\n\n    button {\n      border: none !important;\n      background-color: rgba(99, 67, 115, 0.4) !important;\n      color: #db74ff;\n      font-size: 0.9rem;\n      padding: 5px 15px !important;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      gap: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payEmbed": `styled_payEmbed__ZKsvv`,
	"backButton": `styled_backButton__9BzRy`
};
export default ___CSS_LOADER_EXPORT___;
