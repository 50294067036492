// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_tooltip__SETgZ {
  position: fixed;
  min-width: 120px;
  color: #fff;
  background-color: black;
  border-radius: 5px;
  opacity: 1;
  z-index: 100;
  padding: 3px;
  text-overflow: ellipsis;
  text-align: center;
  padding: 10px;
  font-family: "Orbitron";
  font-size: 0.9rem;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5019607843);
}
.styled_tooltip__SETgZ:after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/tooltip/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,sDAAA;AACF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uDAAA;AAAJ","sourcesContent":[".tooltip {\n  position: fixed;\n  min-width: 120px;\n  color: #fff;\n  background-color: #000000ff;\n  border-radius: 5px;\n  opacity: 1;\n  z-index: 100;\n  padding: 3px;\n  text-overflow: ellipsis;\n  text-align: center;\n  padding: 10px;\n  font-family: \"Orbitron\";\n  font-size: .9rem;\n  box-shadow: 0 0 10px #ffffff80;\n\n\n  &:after {\n    content: \"\";\n    position: absolute;\n    bottom: -16px;\n    left: 50%;\n    margin-left: -8px;\n    border-width: 8px;\n    border-style: solid;\n    border-color: #000000ff transparent transparent transparent;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `styled_tooltip__SETgZ`
};
export default ___CSS_LOADER_EXPORT___;
