// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_mainDrawer__NEfxJ .styled_container__MFHN0 {
  backdrop-filter: blur(12px);
  overflow-y: auto;
  width: 450px;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  bottom: 1.5rem;
  border-radius: 1rem;
  z-index: 1;
  box-sizing: border-box;
  border: 1px solid rgba(216, 183, 231, 0.5019607843);
  background: rgba(58, 31, 80, 0.6);
}

.styled_menuMobile__TJQMP {
  position: absolute;
  width: 100%;
  top: 100%;
  height: 100%;
  transition: 0.3s all;
  background: #150a21;
  z-index: 1000;
  /*border: 1px solid yellow;*/
}
.styled_menuMobile__TJQMP .styled_downIcon__OefMi {
  position: absolute;
  top: -60px;
  left: calc(50% - 100px);
  width: 200px;
  z-index: 900;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  color: #ddd;
  gap: 5px;
  font-size: 1rem;
  animation: styled_translate__vKdw9 2s linear infinite;
}
.styled_menuMobile__TJQMP .styled_downIcon__OefMi svg {
  height: 25px !important;
  width: 100px !important;
}

@keyframes styled_translate__vKdw9 {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/styled.module.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,UAAA;EACA,sBAAA;EACA,mDAAA;EACA,iCAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,aAAA;EACA,4BAAA;AADF;AAGE;EACE,kBAAA;EACA,UAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,QAAA;EACA,eAAA;EACA,qDAAA;AADJ;AAGI;EACE,uBAAA;EACA,uBAAA;AADN;;AAMA;EACE;IACE,0BAAA;EAHF;EAKA;IACE,2BAAA;EAHF;EAKA;IACE,0BAAA;EAHF;AACF","sourcesContent":[".mainDrawer {\n  .container {\n    backdrop-filter: blur(12px);\n    overflow-y: auto;\n    width: 450px;\n    position: absolute;\n    top: 1.5rem;\n    left: 1.5rem;\n    bottom: 1.5rem;\n    border-radius: 1rem;\n    z-index: 1;\n    box-sizing: border-box;\n    border: 1px solid #d8b7e780;\n    background: rgba(58, 31, 80, 0.6);\n  }\n}\n\n.menuMobile {\n  position: absolute;\n  width: 100%;\n  top: 100%;\n  height: 100%;\n  transition: 0.3s all;\n  background: #150a21;\n  z-index: 1000;\n  /*border: 1px solid yellow;*/\n\n  .downIcon {\n    position: absolute;\n    top: -60px;\n    left: calc(50% - 100px);\n    width: 200px;\n    z-index: 900;\n    display: flex;\n    cursor: pointer;\n    align-items: center;\n    flex-direction: column;\n    color: #ddd;\n    gap: 5px;\n    font-size: 1rem;\n    animation: translate 2s linear infinite;\n\n    svg {\n      height: 25px !important;\n      width: 100px !important;\n    }\n  }\n}\n\n@keyframes translate {\n  0% {\n    transform: translateY(5px);\n  }\n  50% {\n    transform: translateY(-5px);\n  }\n  100% {\n    transform: translateY(5px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDrawer": `styled_mainDrawer__NEfxJ`,
	"container": `styled_container__MFHN0`,
	"menuMobile": `styled_menuMobile__TJQMP`,
	"downIcon": `styled_downIcon__OefMi`,
	"translate": `styled_translate__vKdw9`
};
export default ___CSS_LOADER_EXPORT___;
