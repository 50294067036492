// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.styled_checkbox__Niez\\+ {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #cc6ff8;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  background-color: #ba00fb;
  position: relative;
  margin: 0 0 0 10px;
}
.styled_checkbox__Niez\\+:checked {
  background-color: #d48af7;
}
.styled_checkbox__Niez\\+::after {
  content: "✓";
  font-size: 0.9rem;
  color: #000;
  position: absolute;
  top: -2px;
  left: 3px;
  opacity: 0;
}
.styled_checkbox__Niez\\+:checked::after {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/checkbox/styled.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAEF;AAAE;EACE,yBAAA;AAEJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,UAAA;AAAJ;AAGE;EACE,UAAA;AADJ","sourcesContent":[".checkbox {\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  border: 1px solid #cc6ff8;\n  border-radius: 3px;\n  cursor: pointer;\n  outline: none;\n  background-color: #ba00fb; // Cambiado el color de fondo por default\n  position: relative;\n  margin: 0 0 0 10px;\n\n  &:checked {\n    background-color: #d48af7;\n    //border-color: #d48af7;\n  }\n\n  &::after {\n    content: \"\\2713\";\n    font-size: 0.9rem;\n    color: #000;\n    position: absolute;\n    top: -2px;\n    left: 3px;\n    opacity: 0; // Por defecto no visible\n  }\n\n  &:checked::after {\n    opacity: 1; // Hacer visible cuando está marcado\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `styled_checkbox__Niez+`
};
export default ___CSS_LOADER_EXPORT___;
