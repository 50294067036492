// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_main__EDXP9 {
  height: 100%;
  color: white;
  flex-flow: column;
  box-sizing: border-box;
  transition: 0.2s all;
  position: relative;
}
.styled_main__EDXP9 .styled_header__mK4jT {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1rem;
}
.styled_main__EDXP9 .styled_header__mK4jT .styled_title__V8-ji {
  font-family: "OrbitronBlack";
  font-size: 1.2rem;
  background: linear-gradient(108deg, #fff 3.7%, #c66ae6 93.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styled_main__EDXP9 .styled_header__mK4jT button {
  border: none !important;
  background-color: rgba(99, 67, 115, 0.4) !important;
  color: #db74ff;
  font-size: 0.9rem;
  padding: 5px 15px !important;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.styled_main__EDXP9 .styled_container__yX3aG {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1;
  color: #d490ec;
  box-sizing: border-box;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/newMenu/sections/main/notifications/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AACI;EACE,4BAAA;EACA,iBAAA;EACA,8DAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;AACN;AAEI;EACE,uBAAA;EACA,mDAAA;EACA,cAAA;EACA,iBAAA;EACA,4BAAA;EACA,aAAA;EACA,8BAAA;EACA,QAAA;AAAN;AAIE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;AAFJ","sourcesContent":[".main {\n  height: 100%;\n  color: white;\n  flex-flow: column;\n  box-sizing: border-box;\n  transition: 0.2s all;\n  position: relative;\n\n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    padding: 1rem 1rem;\n\n    .title {\n      font-family: \"OrbitronBlack\";\n      font-size: 1.2rem;\n      background: linear-gradient(108deg, #fff 3.7%, #c66ae6 93.42%);\n      background-clip: text;\n      -webkit-background-clip: text;\n      -webkit-text-fill-color: transparent;\n    }\n    \n    button {\n      border: none !important;\n      background-color: rgba(99, 67, 115, 0.4) !important;\n      color: #db74ff;\n      font-size: 0.9rem;\n      padding: 5px 15px !important;\n      display: flex;\n      justify-content: space-between;\n      gap: 5px;\n    }\n  }\n\n  .container {\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    flex: 1;\n    color: #d490ec;\n    box-sizing: border-box;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `styled_main__EDXP9`,
	"header": `styled_header__mK4jT`,
	"title": `styled_title__V8-ji`,
	"container": `styled_container__yX3aG`
};
export default ___CSS_LOADER_EXPORT___;
