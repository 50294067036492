import customAxios from "utils/customAxios";

import {
  setUserInfoFailure,
  setUserInfoStart,
  setUserInfoSuccess,
  clearUserInfo as clearUI,
} from "reduxConfig/slices/userInfo";

export const fetchUserInfo = () => async (dispatch: any) => {
  dispatch(setUserInfoStart());
  try {
    const response = await customAxios().get("user/info");

    const data = response?.data?.result || {};
    dispatch(setUserInfoSuccess(data));
    return response.data;
  } catch (error: any) {
    dispatch(setUserInfoFailure(error?.message));
  }
};

export const clearUserInfo = () => async (dispatch: any) => {
  try {
    dispatch(clearUI);
  } catch (error: any) {
    dispatch(setUserInfoFailure(error?.message));
  }
};

export const getUserInfo = (state: any) => state.userInfo.data;
