import customAxios from "utils/customAxios";

import {
  setLeaderboardListFailure,
  setLeaderboardListStart,
  setLeaderboardListSuccess,
  clearLeaderboardList as clearLBList,
} from "reduxConfig/slices/leaderboardList";

export const fetchLeaderboardList = () => async (dispatch: any) => {
  dispatch(setLeaderboardListStart());
  try {
    const response = await customAxios().get("user/leaderboard");

    const data = response?.data?.result || {};
    dispatch(setLeaderboardListSuccess(data));
    return response.data;
  } catch (error: any) {
    dispatch(setLeaderboardListFailure(error?.message));
  }
};

export const clearLeaderboardList = () => async (dispatch: any) => {
  try {
    dispatch(clearLBList);
  } catch (error: any) {
    dispatch(setLeaderboardListFailure(error?.message));
  }
};

export const getLeaderboardList = (state: any) => state.leaderboardList.data;
