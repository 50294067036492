// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_loggedBox__LiuK5 {
  border: 1px solid rgba(186, 0, 251, 0.41);
  background: rgba(58, 31, 80, 0.6);
  backdrop-filter: blur(4px);
  padding: 15px 20px;
  color: #d490ec;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
}
.styled_loggedBox__LiuK5 span {
  color: #fff;
  font-family: "ChakraPetchLight";
  font-size: 0.9rem;
  font-weight: 600;
}
.styled_loggedBox__LiuK5 svg {
  cursor: pointer;
}
.styled_loggedBox__LiuK5 .styled_divider__pAKAK {
  width: 1px;
  height: 25px;
  border-left: 1px dashed rgba(186, 0, 251, 0.6666666667);
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/login/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,iCAAA;EACA,0BAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,+BAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACE;EACE,eAAA;AACJ;AACE;EACE,UAAA;EACA,YAAA;EACA,uDAAA;AACJ","sourcesContent":[".loggedBox {\n  border: 1px solid rgba(186, 0, 251, 0.41);\n  background: rgba(58, 31, 80, 0.6);\n  backdrop-filter: blur(4px);\n  padding: 15px 20px;\n  color: #d490ec;\n  display: flex;\n  align-items: center;\n  border-radius: 0.5rem;\n  gap: 0.5rem;\n\n  span {\n    color: #fff;\n    font-family: \"ChakraPetchLight\";\n    font-size: 0.9rem;\n    font-weight: 600;\n  }\n  svg {\n    cursor: pointer;\n  }\n  .divider {\n    width: 1px;\n    height: 25px;\n    border-left: 1px dashed #ba00fbaa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loggedBox": `styled_loggedBox__LiuK5`,
	"divider": `styled_divider__pAKAK`
};
export default ___CSS_LOADER_EXPORT___;
