// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_alert__ek1Ue {
  position: fixed;
  top: 1.5rem;
  width: 100%;
  max-width: 400px;
  left: calc(50% - 200px);
  color: #fff !important;
  font-size: 1rem !important;
  font-family: "ChakraPetch" !important;
  box-shadow: 0 0 5px #222;
  border-radius: 6px;
  display: flex;
  align-items: center;
  z-index: 9999;
  box-sizing: border-box;
}
.styled_alert__ek1Ue > div:nth-child(2n) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.styled_alert__ek1Ue.styled_error__NWhrw {
  background-color: rgba(255, 20, 20, 0.8) !important;
}
.styled_alert__ek1Ue.styled_success__hsS3v {
  background-color: rgba(50, 255, 50, 0.8) !important;
}
.styled_alert__ek1Ue path {
  fill: #fff;
}

@media screen and (max-width: 600px) {
  .styled_alert__ek1Ue {
    left: 1rem;
    right: 1rem;
    width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/alert/styled.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;EAEA,sBAAA;EACA,0BAAA;EACA,qCAAA;EACA,wBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AAAF;AAEE;EACE,gBAAA;EACA,uBAAA;AAAJ;AAGE;EACE,mDAAA;AADJ;AAIE;EACE,mDAAA;AAFJ;AAKE;EACE,UAAA;AAHJ;;AAOA;EACE;IACE,UAAA;IACA,WAAA;IACA,WAAA;EAJF;AACF","sourcesContent":[".alert {\n  position: fixed;\n  top: 1.5rem;\n  width: 100%;\n  max-width: 400px;\n  left: calc(50% - 200px);\n  //background-color: rgba(255, 20, 20, 0.8);\n  color: #fff !important;\n  font-size: 1rem !important;\n  font-family: \"ChakraPetch\" !important;\n  box-shadow: 0 0 5px #222;\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  z-index: 9999;\n  box-sizing: border-box;\n\n  > div:nth-child(2n) {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &.error {\n    background-color: rgba(255, 20, 20, 0.8) !important;\n  }\n\n  &.success {\n    background-color: rgba(50, 255, 50, 0.8) !important;\n  }\n  \n  & path {\n    fill: #fff;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .alert {\n    left: 1rem;\n    right: 1rem;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `styled_alert__ek1Ue`,
	"error": `styled_error__NWhrw`,
	"success": `styled_success__hsS3v`
};
export default ___CSS_LOADER_EXPORT___;
