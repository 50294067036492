import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { ranking: [] },
  loading: false,
  error: null,
};

const leaderboardListSlice = createSlice({
  name: "LeaderboardList",
  initialState,
  reducers: {
    setLeaderboardListStart(state) {
      state.loading = true;
      state.error = null;
    },
    setLeaderboardListSuccess(state, { payload }) {
      state.data = payload;
      state.loading = false;
    },
    setLeaderboardListFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    clearLeaderboardList(state) {
      state.data = initialState.data;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
});

export const { setLeaderboardListStart, clearLeaderboardList, setLeaderboardListFailure, setLeaderboardListSuccess } =
  leaderboardListSlice.actions;

export default leaderboardListSlice.reducer;
