import { Routes, Route, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import Home from "pages/home";
import Game from "pages/game";
import Login from "pages/login";
import TelegramLogin from "pages/login/telegram";

import { customAxiosTelegram } from "utils/customAxios";

const languages = ["en", "tr", "pt", "ar", "ko", "ja", "vi", "id", "cn"];

console.log("ENVIRONMENT", process.env.REACT_APP_NODE_ENV);

const AppRouter = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenLS = localStorage.getItem("session.token");
  const localLang = window.navigator?.language.slice(0, 2) || "en";
  const lang = window.location.pathname.split("/")[1] || localLang;

  const Secure = (component: JSX.Element): JSX.Element => {
    const origin = window.location?.pathname.slice(4) + window.location?.search;
    if (!tokenLS) return <Navigate to={`/${lang}/?origin=${encodeURIComponent(origin)}`} />;
    return component;
  };

  useEffect(() => {
    const externalLogin = async () => {
      if (searchParams.get("start") === "app") {
        const telegram = window?.Telegram;
        if (telegram?.WebApp?.platform !== "unknown") {
          if (telegram?.WebApp?.initData) {
            const initData = JSON.parse(
              '{"' + telegram?.WebApp?.initData.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
              function (key, value) {
                return key === "" ? value : decodeURIComponent(value);
              }
            );
            const user = JSON.parse(initData.user);
            const response: any = await customAxiosTelegram().post("/auth/telegram/signature", { id: user?.id });
            const { signature, message } = response.data.data;
            navigate(`/${i18n.language}/login/telegram/${signature}/${encodeURI(message)}`);
          }
        }
      }

      const token = searchParams.get("landing");
      if (token) {
        searchParams.delete("landing");
        const response: any = await customAxiosTelegram().post("/auth/telegram/signature", { token });
        const { signature, message } = response.data.data;
        navigate(`/${i18n.language}/login/telegram/${signature}/${encodeURI(message)}?token=${token}`);
      }
    };

    externalLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const langExist = languages.find((x) => x === lang);
    if (langExist) {
      i18n.changeLanguage(lang);
    } else {
      const localLangExist = languages.find((x) => x === localLang);
      if (localLangExist) {
        i18n.changeLanguage(localLang);
        navigate(`/${localLang}/?${decodeURIComponent(searchParams.toString())}`);
      } else {
        navigate(`/en/?${decodeURIComponent(searchParams.toString())}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to={`/${lang}/`} />} />
      <Route path="/" element={<Navigate to={`/${lang}/`} />} />
      <Route path="/:lang" element={<Login />} />
      <Route path="/:lang/home/" element={Secure(<Home />)} />
      <Route path="/:lang/home/:map" element={Secure(<Home />)} />
      <Route path="/:lang/home/:map/:building" element={Secure(<Home />)} />
      <Route path="/:lang/game/:code" element={Secure(<Game />)} />
      <Route path="/:lang/login/telegram/:signature/:message" element={<TelegramLogin />} />
    </Routes>
  );
};
export default AppRouter;
