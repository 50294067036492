import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./i18n";

import store from "reduxConfig/store";
import { Provider } from "react-redux";
import { ThirdwebProvider } from "thirdweb/react";
import { AlertProvider } from "hooks/alertProvider/alertContext";

import App from "App";
import "./index.css";
import SDKInitializer from "utils/sdkInitiliazer";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Helmet>
      <script
        src="https://cdn.markfi.xyz/scripts/analytics/0.11.21/cookie3.analytics.min.js"
        integrity="sha384-wtYmYhbRlAqGwxc5Vb9GZVyp/Op3blmJICmXjRiJu2/TlPze5dHsmg2gglbH8viT"
        crossOrigin="anonymous"
        async
        site-id={process.env.REACT_APP_COOKIE3_SITE_ID}
      />
    </Helmet>
    <BrowserRouter>
      <Provider store={store}>
        <ThirdwebProvider>
          <AlertProvider>
            <SDKInitializer />
            <App />
          </AlertProvider>
        </ThirdwebProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
